.HeaderNav {
    height: 108%;
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 4fr;
    margin-bottom: 20px;
    &_text {
        margin-top: 5%;
        grid-column: 1/2;
        color: black !important;
    }
    &_links {
        margin-top: 60px;
        margin-left: 5vw;
        font-weight: 400;
        font-family: 'Open Sans';
    }
    &_links {
        grid-row: 2/3;
        display: flex;
        justify-content: space-around;
    }
    &_imgWrap {
        grid-column: 2/3;
        grid-row: 1/3;
        z-index: -10;
    }
    &_img {
        width: 30vw;
        height: 35vh;
        object-fit: cover;
        box-shadow: 12px;
        box-shadow: 0px 0px 73px -29px rgba(0, 0, 0, 0.75);
    }
}

.HeaderLink {
    margin-left: 10px;
    &_link {
        color: black;
    }
}

@media (max-width: 600px) {
    .HeaderNav {
        position: relative;
        grid-template-columns: 1fr;
        grid-template: 1fr;
        &_text {
            margin: 0;
            height: 100%;
            width: 100%;
            z-index: 10;
            grid-row: 1;
            grid-column: 1;
            background-color: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &_text h2 {
            color: black;
        }
        &_links {
            color: white;
            background-color: none;
        }
        &_imgWrap {
            z-index: -10;
            grid-row: 1;
            grid-column: 1;
            display: none;
        }
        &_img {
            width: 100%;
            height: 100%;
            display: none;
        }
    }
}