.MainPR {
    height: 100%;
    margin: 25px 0px 65px 0px;
    &_head {
        margin: 0 0 25px 0;
    }
    &_ComponentsWrapper {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 1fr);
        justify-items: center;
        background-color: #f3f3f3;
        box-shadow: 0px 0px 73px -29px rgba(0, 0, 0, 0.75);
    }
}

.PRComponent {
    border: 1px $BTGREEN solid;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 25px;
    width: 100%;
    &_link {
        text-decoration: none !important;
        align-self: center;
    }
    &_title {
        font-family: "Roboto Mono";
        color: black;
        text-decoration: none !important;
        transition: 0.25s ease;
        &:hover {
            color: $BTGREEN !important;
            text-decoration: none !important;
        }
    }
    &_cont {
        width: 90%;
        padding: 10px;
    }
    &_text {
        text-align: center;
        font-family: "Nunito Sans" !important;
        font-size: 16px;
    }
    &_title_cont {
        padding: 10px !important;
    }
    // &_imgwrap {
    //     background-color: rgb(211, 211, 211);
    // }
    &_img {
        &_tall {
            height: 250px;
            width: 200px;
            object-position: 50% 50%;
        }
        &_short {
            height: 150px;
            width: 200px;
            object-position: 50% 50%;
        }
    }
    &_morelinkcont {
        display: flex
    }
    &_morelink {
        height: 100%;
        grid-column: 3/4;
    }
    &_more {
        transform: rotate(90deg);
        margin: 0;
    }
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}

@media (max-width: 648px) {
    .PRComponent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        &_link {
            width: 100%;
            display: flex;
            align-items: center;
            text-align: center;
        }
        &_title {
            font-size: 17px !important;
            line-height: 20px !important;
            color: #07b46f !important;
        }
        &_title_cont {
            align-self: center;
            justify-self: center;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }
        &_text {
            font-size: 14px !important;
        }
        // &_img {
        //     &_short {
        //         display: none;
        //     }
        //     &_tall {
        //         display: none;
        //     }
        // }
        &_imgwrap {
            display: flex;
            justify-content: center;
        }
    }
}