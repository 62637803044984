.MediaKit {
  height: 125vh;
}
.MediaKit h2 {
  margin: 35px 0 25px 0;
}
.MediaKit_media {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
}
.MediaKit_container {
  margin-top: 10px;
  width: 100%;
  height: 75vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  padding: 10px;
}
.MediaKit_toggle {
  justify-self: center !important;
  font-size: 30px !important;
  display: block !important;
  position: relative !important;
  font-family: "Nunito Sans" !important;
  font-weight: 600;
}
.MediaKit_section {
  grid-column: 2/3;
  grid-row: 1/2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.MediaKit_videos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.MediaKit_video {
  margin: 15px 0 0 0;
}
.MediaKit_video_contain {
  margin-top: -100px;
  margin-bottom: 30px;
}

.MediaPreview {
  height: 100px;
  width: 150px;
  object-fit: contain;
  transition: all 1s ease;
  background-color: grey;
  border: 1px solid #00c274;
  border-radius: 2px;
}

.MediaLogo_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MediaMapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: space-between;
}
.MediaMapper_item {
  margin: 20px 0 0 0;
  transition: all 0.9s ease;
}
.MediaMapper_item:hover .MediaPreview {
  box-shadow: 0px 0px 100px -12px rgb(0, 0, 0);
  border: 1px solid black;
}

@media (max-width: 765px) {
  .MediaKit_media {
    height: 100px;
  }
  .MediaKit_container {
    height: 180vh;
    grid-template-columns: 1fr;
    grid-template-rows: 300px 0.75fr 300px;
  }
}
.HeaderNav {
  height: 108%;
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-template-rows: 4fr;
  margin-bottom: 20px;
}
.HeaderNav_text {
  margin-top: 5%;
  grid-column: 1/2;
  color: black !important;
}
.HeaderNav_links {
  margin-top: 60px;
  margin-left: 5vw;
  font-weight: 400;
  font-family: "Open Sans";
}
.HeaderNav_links {
  grid-row: 2/3;
  display: flex;
  justify-content: space-around;
}
.HeaderNav_imgWrap {
  grid-column: 2/3;
  grid-row: 1/3;
  z-index: -10;
}
.HeaderNav_img {
  width: 30vw;
  height: 35vh;
  object-fit: cover;
  box-shadow: 12px;
  box-shadow: 0px 0px 73px -29px rgba(0, 0, 0, 0.75);
}

.HeaderLink {
  margin-left: 10px;
}
.HeaderLink_link {
  color: black;
}

@media (max-width: 600px) {
  .HeaderNav {
    position: relative;
    grid-template-columns: 1fr;
    grid-template: 1fr;
  }
  .HeaderNav_text {
    margin: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    grid-row: 1;
    grid-column: 1;
    background-color: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .HeaderNav_text h2 {
    color: black;
  }
  .HeaderNav_links {
    color: white;
    background-color: none;
  }
  .HeaderNav_imgWrap {
    z-index: -10;
    grid-row: 1;
    grid-column: 1;
    display: none;
  }
  .HeaderNav_img {
    width: 100%;
    height: 100%;
    display: none;
  }
}
.QuickLinks {
  background-image: linear-gradient(90deg, #00c274 0%, #45b0e5 100%);
  height: 60vh;
  margin: auto -6vw;
  display: grid;
  grid-template-rows: 5vh 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
.QuickLinks_title {
  margin-top: 25px;
  text-align: center;
  color: white;
  grid-column: 1/-1;
}
.QuickLinks_container {
  grid-column: 1/-1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.QuickLinkComp_img {
  width: 20vw;
  object-fit: cover;
  box-shadow: 12px;
  box-shadow: 0px 0px 73px -29px rgba(0, 0, 0, 0.75);
}
.QuickLinkComp_link {
  color: white !important;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  width: 100%;
  transition: all 0.45s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.QuickLinkComp_link:hover {
  color: black !important;
  font-size: 20px !important;
  text-decoration: none !important;
}

@media (max-width: 830px) {
  .QuickLinks {
    height: 105vh;
  }
  .QuickLinks_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .QuickLinkComp_img {
    width: 28vw;
  }
  .QuickLinkComp_link {
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}
@media (max-width: 520px) {
  .QuickLinks {
    height: 89vh;
  }
  .QuickLinkComp_img {
    width: 20vh;
  }
  .QuickLinkComp_link {
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}
.MainPR {
  height: 100%;
  margin: 25px 0px 65px 0px;
}
.MainPR_head {
  margin: 0 0 25px 0;
}
.MainPR_ComponentsWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fit, 1fr);
  justify-items: center;
  background-color: #f3f3f3;
  box-shadow: 0px 0px 73px -29px rgba(0, 0, 0, 0.75);
}

.PRComponent {
  border: 1px #00c274 solid;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr 25px;
  width: 100%;
}
.PRComponent_link {
  text-decoration: none !important;
  align-self: center;
}
.PRComponent_title {
  font-family: "Roboto Mono";
  color: black;
  text-decoration: none !important;
  transition: 0.25s ease;
}
.PRComponent_title:hover {
  color: #00c274 !important;
  text-decoration: none !important;
}
.PRComponent_cont {
  width: 90%;
  padding: 10px;
}
.PRComponent_text {
  text-align: center;
  font-family: "Nunito Sans" !important;
  font-size: 16px;
}
.PRComponent_title_cont {
  padding: 10px !important;
}
.PRComponent_img_tall {
  height: 250px;
  width: 200px;
  object-position: 50% 50%;
}
.PRComponent_img_short {
  height: 150px;
  width: 200px;
  object-position: 50% 50%;
}
.PRComponent_morelinkcont {
  display: flex;
}
.PRComponent_morelink {
  height: 100%;
  grid-column: 3/4;
}
.PRComponent_more {
  transform: rotate(90deg);
  margin: 0;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

@media (max-width: 648px) {
  .PRComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .PRComponent_link {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .PRComponent_title {
    font-size: 17px !important;
    line-height: 20px !important;
    color: #07b46f !important;
  }
  .PRComponent_title_cont {
    align-self: center;
    justify-self: center;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .PRComponent_text {
    font-size: 14px !important;
  }
  .PRComponent_imgwrap {
    display: flex;
    justify-content: center;
  }
}
.headlink {
  font-family: "Roboto Mono";
  text-decoration: none;
  color: #00c274;
  font-weight: 300;
  cursor: default;
}
.headlink:hover {
  cursor: default;
}

.bottegaSiteImage {
  width: 300px;
  margin-bottom: 50px;
}

.app {
  margin: 20px 6vw 8vh 6vw;
}

html {
  font-size: 62.5%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Roboto Mono";
}

body {
  overflow-x: hidden !important;
  font-weight: normal !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  -webkit-tap-highlight-color: transparent !important;
  font-family: "Nunito Sans" !important;
  font-size: 16pt !important;
  font-size: 16pt !important;
}

h1 {
  font-family: "Roboto Mono" !important;
  font-size: 60px !important;
  font-weight: 400 !important;
}

h2 {
  font-family: "Roboto Mono" !important;
  font-size: 22pt !important;
  font-weight: 300 !important;
}

h3 {
  font-family: "Roboto Mono" !important;
  font-size: 22px !important;
  line-height: 40px !important;
  font-weight: 400 !important;
}

h4 {
  font-family: "Roboto Mono" !important;
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 300 !important;
}

p {
  font-family: "Nunito Sans" !important;
  font-size: 20px !important;
}

a {
  text-decoration: none !important;
  font-family: "Nunito Sans" !important;
  color: #00c274 !important;
  transition: all 0.3s ease;
}
a:hover {
  color: black !important;
  font-weight: 400 !important;
}