.MediaKit {
    // background-color: grey;
    height: 125vh;
    & h2 {
        margin: 35px 0 25px 0;
    }
    &_media {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 50vh;
    }
    &_container {
        margin-top: 10px;
        width: 100%;
        height: 75vh;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        padding: 10px;
    }
    &_toggle {
        justify-self: center !important;
        font-size: 30px !important;
        display: block !important;
        position: relative !important;
        font-family: 'Nunito Sans' !important;
        font-weight: 600;
    }
    &_section {
        grid-column: 2/3;
        grid-row: 1/2;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &_videos {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    &_video {
        margin: 15px 0 0 0;
        &_contain {
            margin-top: -100px;
            margin-bottom: 30px;
        }
    }
}

.MediaPreview {
    height: 100px;
    width: 150px;
    object-fit: contain;
    transition: all 1s ease;
    background-color: grey;
    border: 1px solid $BTGREEN;
    border-radius: 2px;
}

.MediaLogo {
    &_link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.MediaMapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: space-between;
    &_item {
        margin: 20px 0 0 0;
        transition: all .9s ease;
        &:hover {
            .MediaPreview {
                box-shadow: 0px 0px 100px -12px rgb(0, 0, 0);
                border: 1px solid black;
            }
        }
    }
}

@media (max-width: 765px) {
    .MediaKit {
        &_media {
            height: 100px;
        }
        &_container {
            height: 180vh;
            grid-template-columns: 1fr;
            grid-template-rows: 300px .75fr 300px;
        }
    }
}