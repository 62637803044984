.QuickLinks {
    background-image: linear-gradient(90deg, #00c274 0%, #45b0e5 100%);
    height: 60vh;
    margin: auto -6vw;
    display: grid;
    grid-template-rows: 5vh 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    &_title {
        margin-top: 25px;
        text-align: center;
        color: white;
        grid-column: 1/-1;
    }
    &_container {
        grid-column: 1/-1;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.QuickLinkComp {
    &_img {
        width: 20vw;
        object-fit: cover;
        box-shadow: 12px;
        box-shadow: 0px 0px 73px -29px rgba(0, 0, 0, 0.75);
    }
    &_link {
        color: white !important;
        text-align: center;
        text-decoration: none;
        font-size: 20px;
        width: 100%;
        transition: all .45s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
            color: black!important;
            font-size: 20px!important;
            text-decoration: none!important;
        }
    }
}

@media (max-width: 830px) {
    .QuickLinks {
        height: 105vh;
        &_container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .QuickLinkComp {
        &_img {
            width: 28vw;
        }
        &_link {
            justify-content: center;
            align-items: center;
            margin-top: 40px;
        }
    }
}

@media (max-width: 520px) {
    .QuickLinks {
        height: 89vh;
    }
    .QuickLinkComp {
        &_img {
            width: 20vh;
        }
        &_link {
            justify-content: center;
            align-items: center;
            margin-top: 40px;
        }
    }
}